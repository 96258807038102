import { ArrowDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Icon,
  Img,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/all";
import { selectOrgProperties } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import menuIconStore from "../../assets/images/menu-icon-store.png";
import searchIcon from "../../assets/images/search_icon.png";
import { trackClick } from "../../services/tracking.service";
import { primary, primaryLight } from "../../theme/colors";
import ImportHistory from "./ImportHistory";
import { ImportStoresSteps } from "./ImportStoresSteps";
import StoreForm from "./StoreForm";
import StoreSearchGrid from "./StoreSearchGrid";

const pluralize = require("pluralize");

export default function UploadStoresTab({
  storeTypes,
}: {
  storeTypes?: (string | undefined)[];
}) {
  const orgProperties = useAppSelector(selectOrgProperties);
  const [tabActive, setTabActive] = useState(0);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  return (
    <VStack w="100%" h="100%" textAlign="start" spacing={5}>
      {!tabActive && (
        <>
          <SimpleGrid
            w="100%"
            columns={!storeTypes?.includes("popup_store") ? 3 : 2}
            textAlign="center"
            spacing={5}
          >
            {!storeTypes?.includes("popup_store") && (
              <VStack
                rounded="lg"
                p={10}
                border={`1px solid ${primaryLight}`}
                flex={1}
                bg="white"
              >
                <Box pos="relative" boxSize="4em">
                  <Button
                    variant="link"
                    as="div"
                    padding="12px 10px"
                    isActive
                    _hover={{ textDecoration: "none" }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    leftIcon={
                      <Img
                        src={searchIcon}
                        height="1rem"
                        width="1rem"
                        filter="invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                      />
                    }
                    iconSpacing={0}
                  >
                    Search
                  </Button>
                  <ArrowDownIcon
                    pos="absolute"
                    bottom={0}
                    right={0}
                    bg="white"
                    rounded="lg"
                    boxSize="1em"
                    color={primary}
                  />
                </Box>
                <Button
                  colorScheme="blue"
                  onClick={() =>
                    trackClick(
                      "store-select-from-dathic",
                      storeTypes?.[0] || "",
                      () => setTabActive(1)
                    )
                  }
                >
                  Select from Dathic
                </Button>
                <Text fontSize="sm">
                  This is the best option to add {pluralize(storeLabel)} or
                  restaurants from big chains in the United States.
                </Text>
                <Spacer />
                <Button
                  variant="link"
                  w="100%"
                  whiteSpace="initial"
                  onClick={() =>
                    window.open(
                      "https://dathic.notion.site/How-to-add-stores-by-searching-from-Dathic-s-database-2baa343e88a3402ca7b4402aa9425b18?pvs=4"
                    )
                  }
                >
                  Learn how to select stores from Dathic store database
                </Button>
              </VStack>
            )}
            <VStack
              rounded="lg"
              p={10}
              border={`1px solid ${primaryLight}`}
              flex={1}
              bg="white"
            >
              <Box pos="relative" boxSize="4em">
                <Button
                  variant="link"
                  as="div"
                  padding="12px 10px"
                  isActive
                  _hover={{ textDecoration: "none" }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  leftIcon={
                    <Img
                      src={menuIconStore}
                      height="1rem"
                      width="1rem"
                      filter="invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                    />
                  }
                  iconSpacing={0}
                >
                  Stores
                </Button>
                <ArrowDownIcon
                  pos="absolute"
                  bottom={0}
                  right={0}
                  bg="white"
                  rounded="lg"
                  boxSize="1em"
                  color={primary}
                />
              </Box>
              <Button
                colorScheme="blue"
                onClick={() =>
                  trackClick("store-add-manually", storeTypes?.[0] || "", () =>
                    setTabActive(3)
                  )
                }
              >
                Add {storeLabel} manually
              </Button>
              <Text fontSize="sm">
                Best option to create one or a few {pluralize(storeLabel)},
                especially independent shops, restaurants, or pop-ups not part
                of a big chain.
              </Text>
              <Spacer />
              <Button
                variant="link"
                w="100%"
                whiteSpace="initial"
                onClick={() =>
                  window.open(
                    "https://dathic.notion.site/Create-a-store-by-filling-out-the-fields-ca322f43882845298ca8d188b84b5b00?pvs=4"
                  )
                }
              >
                Learn about create a {storeLabel} manually
              </Button>
            </VStack>
            <VStack
              rounded="lg"
              p={10}
              border={`1px solid ${primaryLight}`}
              flex={1}
              bg="white"
            >
              <Box pos="relative" boxSize="4em">
                <Button
                  variant="link"
                  as="div"
                  padding="12px 10px"
                  isActive
                  _hover={{ textDecoration: "none" }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  leftIcon={
                    <Icon
                      as={BsFileEarmarkSpreadsheetFill}
                      boxSize="1rem"
                      color="blue.400"
                    />
                  }
                  iconSpacing={0}
                >
                  File
                </Button>
                <ArrowDownIcon
                  pos="absolute"
                  bottom={0}
                  right={0}
                  bg="white"
                  rounded="lg"
                  boxSize="1em"
                  color={primary}
                />
              </Box>
              <Button
                colorScheme="blue"
                onClick={() =>
                  trackClick(
                    "store-from-spreadsheet",
                    storeTypes?.[0] || "",
                    () => setTabActive(2)
                  )
                }
                display="flex"
                flexDir="column"
              >
                Import from a spreadsheet
              </Button>
              <Text fontSize="sm">
                This is the best option to add 10 or more point of sale not part
                of big chains. You'll need to associate stores with products
                manually.
              </Text>
              <Spacer />
              <Button
                variant="link"
                w="100%"
                whiteSpace="initial"
                onClick={() =>
                  window.open(
                    "https://dathic.notion.site/Add-stores-uploading-a-spreadsheet-77ef27e11b7846afa6694f3f00ab5214?pvs=4"
                  )
                }
              >
                Learn about import from Spreadsheet
              </Button>
            </VStack>
          </SimpleGrid>
          <ImportHistory />
        </>
      )}
      {tabActive === 1 && (
        <StoreSearchGrid
          storeTypes={storeTypes}
          onFinish={() => setTabActive(0)}
        />
      )}
      {tabActive === 3 && (
        <StoreForm onFinish={() => setTabActive(0)} storeTypes={storeTypes} />
      )}
      {tabActive === 2 && (
        <ImportStoresSteps
          onFinish={() => setTabActive(0)}
          storeTypes={storeTypes}
        />
      )}
    </VStack>
  );
}
